export const maskMiddle = (input) => {
  const str = String(input);
  const length = str.length;
  if (length <= 6) {
    const start = 1;
    const end = length - 1;
    let maskedStr = str.substring(0, start) + '*'.repeat(4) + str.substring(end);
    return maskedStr;
  }
  const start = 3;
  const end = length - 3;
  let maskedStr = str.substring(0, start) + '*'.repeat(4) + str.substring(end);
  return maskedStr;
};